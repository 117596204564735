@font-face {
  font-family: 'iranSans';
  src: url('../public/assets/fonts/IRANSansXFaNum-Regular.woff2') format('woff2'),
  url("../public/assets/fonts/IRANSansXFaNum-Regular.woff") format('woff'),
  url("../public/assets/fonts/IRANSansXFaNum-Regular.ttf") format('truetype');
}

body {
  margin: 0;
  font-family: "iranSans" !important;
  direction:rtl !important;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
